.fed21-h1 {
  text-decoration: none;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 30px;
  font-family: Anton, Helvetica, Arial, sans-serif;
}

.fed21-h2 {
  text-decoration: none;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 22px;
  font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
}

.fed21-h3 {
  font-size: 18px;
  font-weight: normal;
  font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  line-height: 22px;
}

.fed21-p {
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  font-family: Helvetica, Arial, serif;
  line-height: 22px;
  vertical-align: top;
}

@media screen and (min-width: 1058px) {
  html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
  }
  body { padding-right: 0 !important }
}