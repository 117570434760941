/* Used only for Draft.JS. */

.wrapper-class-draftjs {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  height: 400px !important;
}

.editor-class-draftjs {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.toolbar-class-draftjs {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}

.toolbar-class-tokens-ul {
  visibility: hidden;
}

.toolbar-class-tokens-li:hover {
  background: #f1f1f1;
}

.skeleton-pull-right {
  float: right;
}
