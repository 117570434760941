.MuiMenu-list {
  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #00a651;
    color: white;
  }
}

.MuiFormControl-root {
  .MuiInputLabel-outlined.MuiInputLabel-outlined.MuiInputLabel-shrink{
    transform: translate(0, -6px) scale(0.75);
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(0, 20px) scale(1);
    pointer-events: none;
  }
}


